import { template as template_6ee8d37f3e2c4238a7c0d91ca7137ba0 } from "@ember/template-compiler";
const FKLabel = template_6ee8d37f3e2c4238a7c0d91ca7137ba0(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
