import { template as template_e0f8c1070ce941f7a78e1f3f99c761b0 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e0f8c1070ce941f7a78e1f3f99c761b0(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
